export const color = {
  logo: "#41b549",
  primaryGreen: "#00aa5b",
  primaryRed: "#F94D63",
  primaryGray: "#D6DFEB",
};

export const breakpoints = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
};
