/** @jsxImportSource @emotion/react */
import { Icon } from "@iconify-icon/react";
import { breakpoints, color } from "../../utils/constants";
import { css } from "@emotion/react";

export function Navbar() {
  return (
    <header
      css={css`
        margin: 0;
        padding: 15px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 6px -1px;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
      `}
    >
      <div
        css={css`
          max-width: 768px;
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: ${breakpoints.sm}) {
            justify-content: space-between;
          }
        `}
      >
        <h1
          css={css`
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            color: ${color.logo};
          `}
        >
          Phonebook
        </h1>
        <button
          css={css`
            display: none;
            @media (min-width: ${breakpoints.sm}) {
              background-color: ${color.primaryGreen};
              color: white;
              padding: 10px 15px;
              border-radius: 8px;
              border: none;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              cursor: pointer;
              animation: all 0.2ms ease;
              &:hover {
                background-color: ${color.logo};
              }
            }
          `}
        >
          <Icon icon="ph:plus-bold" />
          Add New Contact
        </button>
      </div>
    </header>
  );
}
