/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ContactType, PhoneType } from "../../types";
import { Icon } from "@iconify-icon/react";
import { color } from "../../utils/constants";

export function FavoriteContact({
  first_name,
  last_name,
  phones,
}: ContactType) {
  return (
    <li
      aria-label="favorite-contact"
      css={css`
        list-style: none;
        border-radius: 8px;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 5px;
        border: 1px solid ${color.primaryGray};
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px 0px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 10px;
        `}
      >
        <h3
          css={css`
            margin: 0;
          `}
        >{`${first_name} ${last_name}`}</h3>
        <ul
          css={css`
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 0;
          `}
        >
          {phones.map(({ number }: PhoneType, index: number) => (
            <li
              key={index}
              css={css`
                list-style: none;
                display: flex;
                gap: 5px;
                justify-content: flex-start;
                align-items: center;
              `}
            >
              <Icon
                icon="ph:phone-call-fill"
                css={css`
                  color: ${color.primaryGreen};
                `}
              />{" "}
              {number}
            </li>
          ))}
        </ul>
      </div>
      <Icon
        icon="ph:heart-fill"
        css={css`
          color: ${color.primaryRed};
          align-self: flex-end;
          font-size: 24px;
        `}
      />
    </li>
  );
}
