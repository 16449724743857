/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Icon } from "@iconify-icon/react";
import { contacts, favoriteContacts } from "./data";
import { ContactType } from "./types";
import { breakpoints, color } from "./utils/constants";
import { Navbar } from "./components/layout";
import { FavoriteContact } from "./components/contact";
import RegularContact from "./components/contact/regular-contact";

function App() {
  return (
    <>
      <Navbar />
      <main
        css={css`
          padding: 0 15px;
        `}
      >
        <div
          css={css`
            max-width: 768px;
            width: 100%;
            margin: auto;
            padding: 10px 0;
          `}
        >
          <button
            css={css`
              background-color: ${color.primaryGreen};
              &:hover {
                background-color: ${color.logo};
              }
              width: 50px;
              height: 50px;
              border-radius: 100px;
              border: none;
              position: fixed;
              z-index: 10;
              right: 15px;
              bottom: 15px;
              color: white;
              font-weight: 600;
              font-size: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              @media (min-width: ${breakpoints.sm}) {
                display: none;
              }
            `}
          >
            <Icon icon="ph:plus-bold" />
          </button>
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 0;
            `}
          >
            {[
              ...favoriteContacts.map(
                ({ first_name, last_name, phones, id }: ContactType) => (
                  <FavoriteContact
                    first_name={first_name}
                    last_name={last_name}
                    phones={phones}
                    id={id}
                    key={id}
                  />
                )
              ),
              contacts.map(
                ({ first_name, last_name, phones, id }: ContactType) => (
                  <RegularContact
                    key={id}
                    first_name={first_name}
                    last_name={last_name}
                    phones={phones}
                    id={id}
                  />
                )
              ),
            ]}
          </ul>
        </div>
      </main>
    </>
  );
}

export default App;
