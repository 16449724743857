import { ContactsType } from "../types";

export const contacts: ContactsType = [
  {
    id: 1,
    first_name: "Fikri Alwan",
    last_name: "Ramadhan",
    phones: [
      {
        number: "081234567890",
      },
      {
        number: "080987654321",
      },
    ],
  },
  {
    id: 2,
    first_name: "Mesut",
    last_name: "Ozil",
    phones: [
      {
        number: "081234567890",
      },
    ],
  },
  {
    id: 3,
    first_name: "Mesut",
    last_name: "Ozil",
    phones: [
      {
        number: "081234567890",
      },
    ],
  },
  {
    id: 4,
    first_name: "Mesut",
    last_name: "Ozil",
    phones: [
      {
        number: "081234567890",
      },
    ],
  },
  {
    id: 5,
    first_name: "Mesut",
    last_name: "Ozil",
    phones: [
      {
        number: "081234567890",
      },
    ],
  },
  {
    id: 6,
    first_name: "Mesut",
    last_name: "Ozil",
    phones: [
      {
        number: "081234567890",
      },
    ],
  },
];
